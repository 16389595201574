@use 'breakpoints' as *;
@use 'colors' as *;
@use 'variables' as *;

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: $zindex-notification;
  top: $navigation-top-height;
  left: 0;
  width: 100vw;

  @include media-breakpoint-up(md) {
    left: unset;
    right: 0;
    top: $navigation-header-height + 1rem;
    width: auto;
    max-width: 33%;
  }
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin-top: 0.5rem;
    border-radius: 1rem 0 0 1rem;
  }
}

.toast-message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0.75rem;

  @include media-breakpoint-up(md) {
    padding: 1rem 2rem;
  }
}

.toast-warning {
  background: map-get($viac-colors, viac-status-warning);
}

.toast-error {
  background: map-get($viac-colors, viac-status-error);
}

.toast-info {
  background: map-get($viac-colors, viac-neutral-100);
  color: map-get($viac-colors, viac-neutral-0);
}

.toast-success {
  background: map-get($viac-colors, viac-neutral-100);
  color: map-get($viac-colors, viac-neutral-0);

  .toast-message::before {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.3101 4.15976C12.5267 4.33099 12.5635 4.64542 12.3923 4.86206L7.16622 11.4741C7.05113 11.6242 6.90216 11.745 6.73145 11.8265C6.55911 11.9088 6.3696 11.9488 6.17869 11.9431C5.98778 11.9374 5.801 11.8862 5.63388 11.7937C5.46873 11.7024 5.32751 11.5733 5.22166 11.4171L3.59132 9.10336C3.43226 8.87762 3.48631 8.56569 3.71204 8.40663C3.93777 8.24757 4.24971 8.30162 4.40877 8.52736L6.04898 10.8551C6.06664 10.8815 6.09023 10.9034 6.118 10.9187C6.14578 10.9341 6.17682 10.9426 6.20855 10.9436C6.24028 10.9445 6.27178 10.9379 6.30042 10.9242C6.32907 10.9105 6.35403 10.8902 6.37323 10.8649L6.37904 10.8573L11.6078 4.24198C11.779 4.02534 12.0934 3.98852 12.3101 4.15976Z' fill='white'/%3E%3C/svg%3E%0A");
    height: 1.5rem;
    width: 1.5rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    transform: scale(1);
    animation: pulse 0.5s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
    }

    70% {
      transform: scale(1.4);
    }

    100% {
      transform: scale(1);
    }
  }
}
