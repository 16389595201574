/* stylelint-disable declaration-no-important */
@use '@angular/material' as mat;
@use 'typography' as typography;
@use 'colors' as *;

$viac-palette: (
  100: map-get($viac-colors, viac-primary-100),
  500: map-get($viac-colors, viac-primary-100),
  700: map-get($viac-colors, viac-primary-100),
  contrast: (
    100: map-get($viac-colors, viac-neutral-100),
    500: map-get($viac-colors, viac-neutral-100),
    700: map-get($viac-colors, viac-neutral-100)
  )
);

$viac-warn-palette: (
  100: map-get($viac-colors, viac-error-100),
  500: map-get($viac-colors, viac-error-100),
  700: map-get($viac-colors, viac-error-100),
  contrast: (
    100: map-get($viac-colors, viac-neutral-100),
    500: map-get($viac-colors, viac-neutral-100),
    700: map-get($viac-colors, viac-neutral-100)
  )
);

$viac-typography: mat.m2-define-typography-config(
  $font-family: "'Cera VIAC', sans-serif",
  $subtitle-1: mat.m2-define-typography-level(3.5rem, 3.5rem, 400, $letter-spacing: -0.02em)
);

$viac-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette($viac-palette, 500, 100, 700),
      accent: mat.m2-define-palette($viac-palette, 500, 100, 700),
      warn: mat.m2-define-palette($viac-warn-palette, 500, 100, 700)
    ),
    typography: $viac-typography
  )
);

@include mat.all-component-themes($viac-theme);
@include mat.core();

/* stylelint-enable declaration-no-important */
