/* stylelint-disable declaration-no-important, selector-class-pattern */

@use 'colors' as *;

.viac-material {
  .mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-slider-handle-width: 20px;
    --mdc-slider-handle-height: 20px;
    --mdc-slider-focus-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-slider-hover-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-slider-active-track-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-slider-inactive-track-color: #{map-get($viac-colors, viac-neutral-15)};
    --mdc-slider-with-tick-marks-active-container-color: #000;
    --mdc-slider-with-tick-marks-inactive-container-color: #{map-get($viac-colors, viac-neutral-100)};

    .mdc-slider__track--inactive {
      opacity: 1;
    }

    .mdc-slider__track--active {
      --mdc-slider-active-track-height: 4px;

      top: 0;
      opacity: 1;
    }

    .mdc-slider__tick-marks {
      .mdc-slider__tick-mark--active,
      .mdc-slider__tick-mark--inactive {
        --mdc-slider-with-tick-marks-container-size: 10px;
        --mdc-slider-with-tick-marks-inactive-container-color: #{map-get($viac-colors, viac-neutral-15)};
        --mdc-slider-with-tick-marks-disabled-container-color: #{map-get($viac-colors, viac-neutral-30)};
        --mdc-slider-with-tick-marks-inactive-container-opacity: 1;
        --mdc-slider-with-tick-marks-active-container-opacity: 1;

        left: 0;
      }
    }
  }

  .mdc-slider__thumb-knob {
    box-shadow: unset !important;
    filter: drop-shadow(0 0 0.5rem map-get($viac-colors, viac-black-25-percent));
  }

  input[type='range']::-webkit-slider-thumb {
    width: 50px !important;
    height: 50px !important;
  }

  .mat-mdc-slider-focus-ripple {
    display: none; // disable focus ripples
  }

  .risk-profile {
    .mat-mdc-slider.mat-primary {
      --mdc-slider-inactive-track-color: linear-gradient(90deg, #60c19f 0%, #d2e9a8 26.15%, #e4f2cb 50.44%, #f7a975 72.92%, #f44f2b 100%);
      --mdc-slider-active-track-color: linear-gradient(90deg, #60c19f 0%, #d2e9a8 26.15%, #e4f2cb 50.44%, #f7a975 72.92%, #f44f2b 100%);

      .mdc-slider__track--inactive {
        background: linear-gradient(90deg, #60c19f 0%, #d2e9a8 26.15%, #e4f2cb 50.44%, #f7a975 72.92%, #f44f2b 100%);
        opacity: 1;
      }

      .mdc-slider__track--active_fill {
        border: unset;
      }
    }
  }

  .life {
    .mat-mdc-slider.mat-primary {
      --mdc-slider-inactive-track-color: #{map-get($viac-colors, viac-neutral-0)};
    }
  }
}

/* stylelint-enabled declaration-no-important, selector-class-pattern */
