@use 'breakpoints' as *;
@use 'colors' as *;

$viac-font-weight-regular: 400;
$viac-font-weight-medium: 500;
$viac-font-weight-bold: 700;

@mixin font-default {
  color: map-get($viac-colors, viac-neutral-100);
  font-family: 'Cera VIAC', sans-serif;
  font-style: normal;
}

@mixin text-default {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
    line-height: 140%;
  }
}

@mixin h3-large {
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.2px;
}

// Enable anti-aliasing
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Apply font and text defaults
body {
  @include font-default;
  @include text-default;

  // fix for ios
  button {
    color: map-get($viac-colors, viac-neutral-100);
  }
}

.text-bright {
  color: map-get($viac-colors, viac-neutral-75);
}

.text-dark {
  color: map-get($viac-colors, viac-neutral-100);
}

// title styles
h1,
.h1 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.002em;
  text-transform: none;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.25em;
    text-transform: uppercase;
  }

  &.caps {
    @include media-breakpoint-down(md) {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.25em;
      text-transform: uppercase;
    }
  }
}

h2 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0;

  @include media-breakpoint-up(md) {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.2px;
  }

  &.xl {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0;

    @include media-breakpoint-up(md) {
      font-size: 1.875rem;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0;
    }
  }

  &.modal-centered {
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 0 6.5rem;
    }
  }
}

h3 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: map-get($viac-colors, viac-neutral-75);

  @include media-breakpoint-up(md) {
    @include h3-large;
  }
}

@mixin h4-style {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0;

  @include media-breakpoint-up(md) {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.002em;
  }
}

h4 {
  @include h4-style;
}

// body text styles
@mixin body-xs {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.1px;

  @include media-breakpoint-up(md) {
    font-size: 0.75rem;
    line-height: 140%;
    letter-spacing: 0.1px;
  }
}

@mixin body-s {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 140%;

  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
    line-height: 140%;
  }
}

@mixin body-m {
  @include text-default;
}

@mixin body-xl {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 150%;
}

@mixin weight-medium {
  font-weight: $viac-font-weight-medium;
}

@mixin weight-bold {
  font-weight: $viac-font-weight-bold;
}

%weight-medium {
  font-weight: $viac-font-weight-medium !important; // stylelint-disable-line declaration-no-important
}

.body-xs {
  @include body-xs;
}

.body-s {
  @include body-s;
}

.body-m {
  @include body-m;
}

.body-xl {
  @include body-xl;
}

.weight-medium {
  @include weight-medium;
}

.weight-bold {
  @include weight-bold;
}

@include media-breakpoint-up(md) {
  .body-md-xs {
    @include body-xs;
  }

  .body-md-s {
    @include body-s;
  }

  .body-md-m {
    @include body-m;
  }

  .body-md-xl {
    @include body-xl;
  }
}

strong {
  font-weight: 500;
}

// list styles
.list-unstyled {
  padding-left: 0;
  list-style-type: none;
}
