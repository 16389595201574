@use 'colors' as *;

@import 'intl-tel-input/build/css/intlTelInput.css';

// stylelint-disable max-nesting-depth, selector-class-pattern, declaration-no-important, no-descending-specificity
.viac-material {
  .phone-number {
    .mdc-text-field {
      overflow: inherit !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      position: unset !important;
    }
  }
}

.iti {
  width: 100%;

  input::placeholder {
    color: map-get($viac-colors, viac-neutral-60) !important;
  }

  .iti__country-container .iti__dropdown-content {
    border-radius: 1rem;
    border: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
    max-height: 13rem;
    left: -0.4375rem;
  }

  .iti__country-list {
    padding: 1rem 0;

    li {
      height: 2.625rem;
      padding: 0.75rem 0.5rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      color: map-get($viac-colors, viac-neutral-75);
      pointer-events: auto;

      .iti__dial-code {
        color: map-get($viac-colors, viac-neutral-100);
        width: 3rem;
      }

      &:hover {
        background-color: map-get($viac-colors, viac-neutral-5) !important;
      }
    }
  }

  .iti__country-list--dropup {
    bottom: calc(100% + 0.5rem);
  }
}

// iti--fullscreen-popup is only used on mobile devices
.iti.iti--container.iti--fullscreen-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(map-get($viac-colors, viac-neutral-100), 0.7);

  .iti__dropdown-content {
    border-radius: 1rem;
  }

  .iti__country-list {
    position: static;
    min-width: unset;
    max-height: unset;
    width: 80vw;
    height: 50vh;
  }
}

// stylelint-enable max-nesting-depth, selector-class-pattern
