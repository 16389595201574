@use 'breakpoints' as *;

.modal-animation-margin-bottom {
  margin-bottom: 2rem;
}

.modal-animation-size {
  height: 12.5rem;

  @include media-breakpoint-up(md) {
    height: 16rem;
  }
}
