/* stylelint-disable selector-class-pattern */

@use 'typography' as typography;

.viac-material {
  .wealth-distribution-select {
    .mat-mdc-select-trigger {
      @include typography.body-s;

      font-weight: 500;
    }
  }

  .mdc-menu-surface.wealth-distribution-select-panel {
    .mat-mdc-option .mdc-list-item__primary-text {
      @include typography.body-s;
    }
  }
}

/* stylelint-enable selector-class-pattern */
