/* stylelint-disable declaration-no-important, selector-class-pattern */
@use 'colors' as *;

.viac-material {
  .table-select {
    .mat-mdc-select-placeholder {
      color: map-get($viac-colors, viac-neutral-100);
      font-weight: 500;
    }

    .trigger {
      display: flex;
      align-items: center;
      font-weight: 500;
    }

    .select-filter-indicator {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      color: map-get($viac-colors, viac-neutral-0);
      background-color: map-get($viac-colors, viac-primary-100);
      font-size: 0.625rem;
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }

  .table-panel {
    &.cdk-overlay-pane {
      min-width: min-content !important;
    }

    .mat-pseudo-checkbox {
      margin-left: 0.5rem;
      border: 1.5px solid map-get($viac-colors, viac-primary-100) !important;
      border-radius: 0.25rem !important;
      margin-right: 1rem !important;
    }

    .empty-option {
      .mat-pseudo-checkbox {
        display: none;
      }
    }

    .mat-mdc-option {
      color: map-get($viac-colors, viac-neutral-100);
      font-size: 0.875rem !important;
      cursor: default;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        height: 1px;
        width: calc(100% - 2rem);
        bottom: 0;
        left: 1rem;
        background-color: map-get($viac-colors, viac-neutral-15);
      }
    }
  }

  .pseudo-mat-option {
    background-color: map-get($viac-colors, viac-neutral-0) !important;

    .mdc-list-item__primary-text {
      color: map-get($viac-colors, viac-neutral-100) !important;
      width: 100%;
    }

    &::after {
      height: 0;
    }
  }
}

/* stylelint-enable declaration-no-important, selector-class-pattern */
