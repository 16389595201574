/* stylelint-disable declaration-no-important, selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */

@use 'colors' as *;
@use 'typography' as *;
@use 'variables' as *;

.viac-material {
  .mat-mdc-checkbox {
    &.mat-accent {
      z-index: $zindex-drop-shadow;

      --mdc-checkbox-selected-checkmark-color: #{map-get($viac-colors, viac-neutral-0)};
      --mdc-checkbox-selected-focus-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-selected-hover-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-selected-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-selected-pressed-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-unselected-focus-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-unselected-hover-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-disabled-selected-icon-color: #{map-get($viac-colors, viac-primary-30)};
      --mdc-checkbox-disabled-unselected-icon-color: #{map-get($viac-colors, viac-primary-30)};
      --mdc-checkbox-unselected-icon-color: #{map-get($viac-colors, viac-primary-100)};
      --mdc-checkbox-unselected-pressed-icon-color: #{map-get($viac-colors, viac-primary-100)};
    }

    .mdc-form-field {
      @include text-default;
      @include weight-medium;

      > label {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .mdc-checkbox {
      align-self: flex-start;
    }

    .mdc-checkbox:hover {
      filter: drop-shadow(0 0 0.5rem map-get($viac-colors, viac-neutral-100-50-percent));
      z-index: $zindex-drop-shadow;
    }

    .mdc-checkbox__ripple {
      display: none; // disable focus ripples
    }

    .mdc-checkbox__background {
      transform: scale(1.333);
      border-radius: 0.25rem !important;
      border-width: 0.0705rem !important;
      background-color: map-get($viac-colors, viac-neutral-0) !important;
    }

    .mdc-checkbox__checkmark {
      transform: scale(0.75);
      background: map-get($viac-colors, viac-neutral-100);
      border-radius: 0.125rem;
    }

    .mdc-checkbox .mdc-checkbox__native-control {
      &:focus-visible ~ .mdc-checkbox__background {
        filter: drop-shadow(0 0 0.5rem map-get($viac-colors, viac-focused));
      }

      &[disabled]:checked ~ .mdc-checkbox__background {
        border-color: #{map-get($viac-colors, viac-primary-30)} !important;

        .mdc-checkbox__checkmark {
          background: map-get($viac-colors, viac-neutral-30) !important;
        }
      }
    }

    .mdc-checkbox__checkmark-path {
      color: map-get($viac-colors, viac-neutral-0);
      transform: scale(0.65) translate(0.375rem, 0.375rem);
      stroke-width: 0.125rem !important;
    }

    .upper-checkbox {
      .mdc-checkbox {
        position: absolute !important;
        top: -0.625rem !important;
        left: -0.5rem !important;
      }

      .mdc-form-field > label {
        position: relative !important;
        left: 2.5rem !important;
        margin-right: 3rem !important;
      }
    }
  }
}

/* stylelint-enable declaration-no-important, selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */
