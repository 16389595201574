.viac-material {
  .table-form-field.mat-mdc-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      background: transparent;
    }

    &.mat-mdc-form-field-has-icon-suffix {
      .mat-mdc-text-field-wrapper {
        padding-right: 0.875rem;
      }
    }
  }
}
