/* stylelint-disable selector-class-pattern */

@use 'colors' as *;
@use 'typography' as *;

.viac-material {
  .mat-mdc-select {
    @include text-default;
    @include weight-medium;

    .mat-mdc-select-arrow {
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14.4694L11.36 9.10988C11.3948 9.07505 11.4361 9.04741 11.4816 9.02856C11.527 9.0097 11.5758 9 11.625 9C11.6742 9 11.723 9.0097 11.7684 9.02856C11.8139 9.04741 11.8552 9.07505 11.89 9.10988L17.25 14.4694' stroke='%23253547' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        no-repeat center;
      transform: rotate(180deg);
      width: 1.5rem;
      height: 1.5rem;

      svg {
        display: none;
      }
    }

    &.mat-mdc-select-disabled {
      .mat-mdc-select-arrow {
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14.4694L11.36 9.10988C11.3948 9.07505 11.4361 9.04741 11.4816 9.02856C11.527 9.0097 11.5758 9 11.625 9C11.6742 9 11.723 9.0097 11.7684 9.02856C11.8139 9.04741 11.8552 9.07505 11.89 9.10988L17.25 14.4694' stroke='%23b0b6bd' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          no-repeat center;
        transform: rotate(180deg);
      }
    }

    &[aria-expanded='true'] {
      .mat-mdc-select-arrow {
        transform: none;
      }
    }

    .mat-mdc-select-trigger {
      @include text-default;
      @include weight-medium;
    }
  }

  .mat-mdc-select-panel.mdc-menu-surface {
    @include shadow-card;

    .mdc-list-item__primary-text + .mat-pseudo-checkbox {
      display: none;
    }

    .mat-mdc-option {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

/* stylelint-enable selector-class-pattern */
