@use 'assets' as *;

/**
Unlike other assets, fonts are not loaded using asset-url() to enable pre-loading (see index.html). When updating the fonts,
make sure to increment the version number in the font file names (e.g. CeraViac-Bold.woff2?v=1) to force a re-download.
 */

@font-face {
  font-family: 'Cera VIAC';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/CeraVIAC-Regular.woff2?v=1');
  font-display: swap;
}

@font-face {
  font-family: 'Cera VIAC';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/CeraVIAC-Bold.woff2?v=1');
  font-display: swap;
}

@font-face {
  font-family: 'Cera VIAC';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/CeraVIAC-Medium.woff2?v=1');
  font-display: swap;
}

@font-face {
  font-family: 'Cera VIAC';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/CeraVIAC-Light.woff2?v=1');
  font-display: swap;
}
