/* stylelint-disable selector-class-pattern */

@use 'colors' as *;

.viac-material {
  .mat-mdc-slide-toggle {
    --mdc-switch-track-width: 3rem;
    --mdc-switch-track-height: 1rem;
    --mdc-switch-track-shape: 0.5rem;
    --mdc-switch-handle-width: 1.5rem;
    --mdc-switch-handle-height: 1.5rem;
    --mat-switch-with-icon-handle-size: 1.5rem;
    --mat-switch-pressed-handle-size: 1.5rem;
    --mdc-switch-handle-shape: 1.5rem;
    --mdc-switch-unselected-track-color: #{map-get($viac-colors, viac-neutral-15)};
    --mdc-switch-unselected-focus-track-color: #{map-get($viac-colors, viac-neutral-15)};
    --mdc-switch-unselected-hover-track-color: #{map-get($viac-colors, viac-neutral-15)};
    --mdc-switch-unselected-pressed-track-color: #{map-get($viac-colors, viac-neutral-15)};
    --mdc-switch-unselected-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-switch-unselected-focus-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-switch-unselected-hover-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-switch-unselected-pressed-handle-color: #{map-get($viac-colors, viac-neutral-100)};
    --mdc-switch-selected-track-color: #{map-get($viac-colors, viac-primary-30)};
    --mdc-switch-selected-focus-track-color: #{map-get($viac-colors, viac-primary-30)};
    --mdc-switch-selected-hover-track-color: #{map-get($viac-colors, viac-primary-30)};
    --mdc-switch-selected-pressed-track-color: #{map-get($viac-colors, viac-primary-30)};
    --mdc-switch-selected-handle-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-switch-selected-focus-handle-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-switch-selected-hover-handle-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-switch-selected-pressed-handle-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-switch-disabled-unselected-track-color: #{map-get($viac-colors, viac-neutral-5)};
    --mdc-switch-disabled-unselected-handle-color: #{map-get($viac-colors, viac-neutral-60)};
    --mdc-switch-disabled-selected-track-color: #{map-get($viac-colors, viac-primary-15)};
    --mdc-switch-disabled-selected-handle-color: #{map-get($viac-colors, viac-primary-60)};
    --mdc-switch-disabled-track-opacity: 1;
    --mat-switch-disabled-selected-handle-opacity: 1;
    --mat-switch-disabled-unselected-handle-opacity: 1;
    --mdc-switch-disabled-unselected-icon-opacity: 1;
    --mdc-switch-disabled-selected-icon-opacity: 1;
  }

  .mdc-switch {
    &:focus-visible .mdc-switch__handle::after {
      box-shadow: 0 0 0.5rem map-get($viac-colors, viac-focused);
    }

    // Pointer events are always enabled for .mdc-switch__handle even if the control is disabled, therefore only apply
    // the hover effect if the control is not disabled
    &:not(.mdc-switch--disabled):hover .mdc-switch__handle::after {
      box-shadow: 0 0 0.5rem map-get($viac-colors, viac-neutral-100-50-percent);
    }
  }

  .mdc-switch__shadow {
    display: none;
  }

  .mdc-switch__ripple::after {
    display: none; // disable focus ripples
  }

  .mdc-switch__icon--off {
    display: none;
  }
}

/* stylelint-enable selector-class-pattern */
