/* stylelint-disable selector-class-pattern */

@use 'colors' as *;
@use 'typography' as *;

.viac-material {
  .card-mat-radio-group {
    gap: 1rem;

    .mat-mdc-radio-button {
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: 0 0 1rem #{map-get($viac-colors, viac-black-10-percent)};
      background: #{map-get($viac-colors, viac-neutral-0)};
    }

    .mdc-form-field {
      display: flex;

      label {
        flex: 1;
        padding-top: 0;
      }
    }

    .mdc-radio:hover {
      filter: none;
    }

    .card-mat-radio-button-enabled {
      cursor: pointer;

      label {
        cursor: pointer;
      }

      &:hover,
      &.mat-mdc-radio-checked {
        box-shadow: 0 0 1rem #{map-get($viac-colors, viac-black-25-percent)};
      }
    }

    viac-tooltip {
      margin-left: 0.5rem;
    }

    viac-radio-button-sub-label {
      margin-top: 0.5rem;
      margin-right: 2rem;
    }
  }

  .card-mat-radio-button-title {
    display: flex;
    align-items: center;
    height: 40px; // same height as mdc-radio
  }

  .card-mat-radio-button-attribute {
    @include body-xs;

    color: #{map-get($viac-colors, viac-primary-100)};
  }

  .card-mat-radio-button-icon {
    margin-left: auto;
    height: 40px;
    width: 40px;
  }
}

/* stylelint-enable selector-class-pattern */
