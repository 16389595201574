/* stylelint-disable declaration-no-important, selector-class-pattern, selector-max-compound-selectors */

@use 'colors' as *;
@use 'typography' as *;
@use 'variables' as *;

.viac-material {
  .mat-mdc-radio-button {
    --mdc-radio-disabled-selected-icon-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-radio-disabled-unselected-icon-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-radio-unselected-hover-icon-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-radio-unselected-icon-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-radio-unselected-pressed-icon-color: #{map-get($viac-colors, viac-primary-100)};
    --mdc-radio-unselected-focus-icon-color: #{map-get($viac-colors, viac-primary-100)};

    // labels
    .mdc-form-field {
      @include text-default;
      @include weight-medium;

      align-items: flex-start;

      label {
        padding-top: 10px;
      }
    }

    .mdc-radio:hover {
      filter: drop-shadow(0 0 0.5rem map-get($viac-colors, viac-neutral-100-50-percent));
      z-index: $zindex-drop-shadow;
    }

    .mdc-radio__background {
      transform: scale(1.2);

      &::before {
        display: none; // disable focus ripples
      }
    }

    .mdc-radio__outer-circle {
      background-color: map-get($viac-colors, viac-neutral-0);
      border-width: 0.0625rem;
    }

    .mdc-radio__native-control:focus-visible ~ .mdc-radio__background .mdc-radio__outer-circle {
      filter: drop-shadow(0 0 0.5rem map-get($viac-colors, viac-focused));
    }

    &.mat-accent .mdc-radio__inner-circle {
      border-color: map-get($viac-colors, viac-neutral-100) !important;
    }

    &.mat-accent {
      z-index: $zindex-drop-shadow;
    }

    // auto-expand
    .auto-expand {
      display: none;
    }

    &.mat-mdc-radio-checked {
      .auto-expand {
        display: block;
      }
    }
  }

  .list-mat-radio-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .inline-mat-radio-group {
    display: flex;
    gap: 2rem;
  }
}

/* stylelint-enable declaration-no-important, selector-class-pattern, selector-max-compound-selectors */
