/* stylelint-disable selector-class-pattern */

@use '@angular/material' as mat;
@use 'typography' as typography;
@use 'colors' as *;

$wealth-detail-chart-select-border: 0.0313rem solid map-get($viac-colors, viac-primary-15);

.viac-material {
  .wealth-detail-chart-select {
    .mat-mdc-select-trigger {
      @include typography.body-s;

      font-weight: 500;
      border: $wealth-detail-chart-select-border;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      padding: 0.5rem 1rem;

      .mat-mdc-select-arrow-wrapper {
        height: 1rem;
      }

      .mat-mdc-select-arrow {
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14.4694L11.36 9.10988C11.3948 9.07505 11.4361 9.04741 11.4816 9.02856C11.527 9.0097 11.5758 9 11.625 9C11.6742 9 11.723 9.0097 11.7684 9.02856C11.8139 9.04741 11.8552 9.07505 11.89 9.10988L17.25 14.4694' stroke='%23b0b6bd' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          no-repeat center;
      }

      .mat-mdc-select-value {
        color: map-get($viac-colors, viac-secondary-dark-60);
      }

      &:hover {
        background-color: map-get($viac-colors, viac-neutral-15);
      }
    }

    &[aria-expanded='true'] {
      .mat-mdc-select-trigger {
        border-radius: 1rem 1rem 0 0;
      }
    }

    &:focus-visible {
      .mat-mdc-select-trigger {
        outline: 0.125rem solid map-get($viac-colors, viac-secondary-dark-60);
        background-color: map-get($viac-colors, viac-neutral-15);
      }
    }
  }

  .mdc-menu-surface.wealth-detail-chart-select-panel {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border: $wealth-detail-chart-select-border;
    border-top: 0;
    border-radius: 0 0 1rem 1rem;

    .mat-mdc-option .mdc-list-item__primary-text {
      @include typography.body-s;

      font-weight: 500;
    }

    .mdc-list-item--selected .mdc-list-item__primary-text {
      color: map-get($viac-colors, viac-secondary-dark-60);
    }
  }
}

/* stylelint-enable selector-class-pattern */
