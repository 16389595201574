@use 'breakpoints' as *;

.viac-small-scrollblock-enabled {
  @include media-breakpoint-down(md) {
    position: fixed;
    width: 100%;
  }
}

.viac-large-scrollblock-enabled {
  @include media-breakpoint-up(md) {
    position: fixed;
    width: 100%;
  }
}
