/* stylelint-disable declaration-no-important, selector-class-pattern */

.viac-material {
  .mdc-dialog__content {
    color: unset !important;
  }

  .mdc-dialog__surface {
    box-shadow: unset !important;
    border-radius: 1rem !important;

    .actions {
      gap: 1rem;
    }
  }
}

/* stylelint-enabled declaration-no-important, selector-class-pattern */
