$viac-colors: (
  viac-black-10-percent: rgba(0, 0, 0, 0.1) /* #000000 */,
  viac-black-25-percent: rgba(0, 0, 0, 0.25) /* #000000 */,
  viac-black-100-percent: rgba(0, 0, 0, 1) /* #000000 */,
  viac-grey: rgba(244, 245, 246, 1) /* #F4F5F6 */,
  viac-grey-80-percent: rgba(244, 245, 246, 0.8) /* #F4F5F6 */,
  viac-home-gradient-end: rgba(192, 227, 197, 1) /* #C0E3C5 */,
  viac-neutral-0: rgba(255, 255, 255, 1) /* #FFFFFF */,
  viac-neutral-0-25-percent: rgba(255, 255, 255, 0.25) /* #FFFFFF */,
  viac-neutral-0-35-percent: rgba(255, 255, 255, 0.35) /* #FFFFFF */,
  viac-neutral-0-80-percent: rgba(255, 255, 255, 0.8) /* #FFFFFF */,
  viac-neutral-5: rgba(244, 245, 246, 1) /* #F4F5F6 */,
  viac-neutral-15: rgba(223, 225, 228, 1) /* #DFE1E4 */,
  viac-neutral-30: rgba(189, 194, 199, 1) /* #BDC2C7 */,
  viac-neutral-60: rgba(176, 182, 189, 1) /* #B0B6BD */,
  viac-neutral-75: rgba(92, 104, 117, 1) /* #5C6875 */,
  viac-neutral-100: rgba(37, 53, 71, 1) /* #253547 */,
  viac-neutral-100-10-percent: rgba(37, 53, 71, 0.1) /* #253547 */,
  viac-neutral-100-20-percent: rgba(37, 53, 71, 0.2) /* #253547 */,
  viac-neutral-100-30-percent: rgba(37, 53, 71, 0.3) /* #253547 */,
  viac-neutral-100-50-percent: rgba(37, 53, 71, 0.5) /* #253547 */,
  viac-primary-5: rgba(245, 251, 249, 1) /* #F5FBF9 */,
  viac-primary-5-80-percent: rgba(245, 251, 249, 0.8) /* #F5FBF9 */,
  viac-primary-15: rgba(226, 243, 239, 1) /* #E2F3EF */,
  viac-primary-30: rgba(195, 230, 221, 1) /* #C3E6DD; */,
  viac-primary-60: rgba(137, 205, 188, 1) /* #89CDBC */,
  viac-primary-100: rgba(58, 172, 144, 1) /* #3AAC90 */,
  viac-primary-100-5-percent: rgba(58, 172, 144, 0.05) /* #3AAC90 */,
  viac-error-5: rgba(255, 248, 246, 1) /* #FFF8F6 */,
  viac-error-10: rgba(255, 242, 238, 1) /* #FFF2EE */,
  viac-error-100: rgba(255, 123, 81, 1) /* #FF7B51 */,
  viac-warning-10: rgb(255, 252, 241, 1) /* #FFFCF1 */,
  viac-warning-40: rgba(253, 245, 218, 1) /* #FDF5DA */,
  viac-warning-100: rgb(226, 176, 0, 1) /* #E2B000 */,
  viac-risk-very-high: rgba(245, 83, 46, 1) /* #F5532E */,
  viac-status-warning: rgba(244, 210, 92, 1) /* #F4D25C */,
  viac-status-error: rgba(255, 123, 81, 1) /* #FF7B51 */,
  viac-status-error-5: rgba(255, 248, 246, 1) /* #FFF8F6 */,
  viac-status-error-40: rgba(255, 202, 185, 1) /* #FF7B51 */,
  viac-risk-high: rgba(255, 173, 122, 1) /* #FFAD7A */,
  viac-secondary-dark-100: rgba(0, 89, 90, 1) /* #00595A */,
  viac-secondary-dark-25: rgba(0, 89, 90, 0.25) /* #00595A */,
  viac-secondary-dark-5: rgba(0, 89, 90, 0.05) /* #00595A */,
  viac-secondary-dark-60: rgba(102, 155, 156, 1) /* #669B9C */,
  viac-secondary-light-100: rgba(160, 219, 168, 1) /* #A0DBA8 */,
  viac-secondary-light-100-15-percent: rgba(160, 219, 168, 0.15) /* #A0DBA8 */,
  viac-secondary-light-60: rgba(198, 233, 203, 1) /* #C6E9CB */,
  viac-secondary-light-40: rgba(226, 244, 228, 1) /* #E2F4E4 */,
  viac-focused-10: rgba(94, 158, 214, 0.1) /* #5E9ED619 */,
  viac-focused: rgba(94, 158, 214, 1) /* #5E9ED6 */,
  viac-portfolio-active-background-default: rgba(235, 247, 237, 1) /* #EBF7ED */,
  viac-portfolio-active-background-light-blue: rgba(227, 253, 255, 1) /* #E3FDFF */,
  viac-portfolio-active-background-light-blue-15-percent: rgba(135, 233, 255, 0.15) /* #87E9FF */,
  viac-portfolio-active-background-blue: rgba(232, 245, 255, 1) /* #E8F5FF */,
  viac-portfolio-active-background-blue-15-percent: rgba(131, 210, 255, 0.15) /* #83D2FF */,
  viac-portfolio-active-background-pink: rgba(255, 239, 249, 1) /* #FFEFF9 */,
  viac-portfolio-active-background-pink-15-percent: rgba(251, 205, 235, 0.15) /* #FBCDEB */,
  viac-portfolio-active-background-orange: rgba(255, 239, 235, 1) /* #FFEFEB */,
  viac-portfolio-active-background-orange-15-percent: rgba(255, 217, 189, 0.15) /* #FFD9BD */,
  viac-portfolio-active-background-yellow: rgba(255, 250, 234, 1) /* #FFFAEA */,
  viac-portfolio-active-background-yellow-15-percent: rgba(255, 243, 137, 0.15) /* #FFF389 */,
  viac-chart-blue-100: rgba(10, 154, 173, 1) /* #0A9AAD */
);

$viac-admin-colors: (
  p3a-light: rgba(218, 232, 192, 1) /* #DAE8C0 */,
  p3a-dark: rgba(69, 138, 37, 1) /* #458A25 */,
  pvb-light: rgba(179, 219, 228, 1) /* #B3DBE4 */,
  pvb-dark: rgba(22, 68, 111, 1) /* #16446F */,
  invest-light: rgba(237, 204, 149, 1) /* #EDCC95 */,
  invest-dark: rgba(221, 113, 13, 1) /* #DD710D */,
  insurance-light: rgba(200, 161, 200, 1) /* #C8A1C8 */,
  insurance-dark: rgba(132, 21, 141, 1) /* #84158D */,
  mortgage-light: rgba(208, 166, 158, 1) /* #D0A69E */,
  mortgage-dark: rgba(112, 70, 61, 1) /* #70463D */,
  product-independet-light: rgba(255, 255, 255, 1) /* #FFFFFF */,
  product-independet-dark: rgba(37, 53, 71, 1) /* #253547 */
);

@mixin gradient-total-asset {
  background: linear-gradient(180deg, #3aac90 33.17%, #8cd1af 43.45%, #eaf6f0 53.25%, #fff 71.18%);
}

@mixin gradient-hub {
  background: linear-gradient(180deg, #3aac90 0%, #c0e3c5 100%);
}

@mixin gradient-cta {
  background: linear-gradient(288.89deg, #253547 30.8%, #374e67 100%);
}

@mixin gradient-portfolio-tile-default {
  background: linear-gradient(104.56deg, rgba(202, 225, 158, 0.6) 0.73%, rgba(160, 219, 168, 0.6) 48.87%);
}

@mixin gradient-new-portfolio-tile {
  background: linear-gradient(104.56deg, rgba(202, 225, 158, 0.2) 0.73%, rgba(160, 219, 168, 0.2) 48.87%);
}

@mixin gradient-portfolios-swiper-left {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 12.5%, rgba(255, 255, 255, 0) 100%);
}

@mixin gradient-portfolios-swiper-right {
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 12.5%, rgba(255, 255, 255, 0) 100%);
}

@mixin gradient-portfolio-name-swiper {
  background: linear-gradient(104.56deg, rgba(202, 225, 158, 0.6) 0.73%, rgba(160, 219, 168, 0.6) 48.87%);
}

@mixin gradient-portfolio-overview-default {
  background: linear-gradient(180deg, map-get($viac-colors, viac-portfolio-active-background-default) 4rem, #fff 10rem);
}

@mixin gradient-portfolio-overview-light-blue {
  background: linear-gradient(180deg, map-get($viac-colors, viac-portfolio-active-background-light-blue) 4rem, #fff 10rem);
}

@mixin gradient-portfolio-overview-blue {
  background: linear-gradient(180deg, map-get($viac-colors, viac-portfolio-active-background-blue) 4rem, #fff 10rem);
}

@mixin gradient-portfolio-overview-pink {
  background: linear-gradient(180deg, map-get($viac-colors, viac-portfolio-active-background-pink) 4rem, #fff 10rem);
}

@mixin gradient-portfolio-overview-orange {
  background: linear-gradient(180deg, map-get($viac-colors, viac-portfolio-active-background-orange) 4rem, #fff 10rem);
}

@mixin gradient-portfolio-overview-yellow {
  background: linear-gradient(180deg, map-get($viac-colors, viac-portfolio-active-background-yellow) 4rem, #fff 10rem);
}

@mixin gradient-custom-strategy-mixer {
  background: linear-gradient(180deg, rgba(234, 234, 234, 0) 0%, rgba(234, 234, 234, 0.39) 100%);
}

@mixin gradient-tooltip-with-scrollbar {
  background: linear-gradient(180deg, rgba(37, 53, 71, 0) 0%, rgba(37, 53, 71, 100) 75%, rgba(37, 53, 71, 1) 100%);
}

@mixin shadow-navigation {
  box-shadow: 0 0 15px rgba(37, 53, 71, 0.08);
}

@mixin shadow-card {
  box-shadow: 0 4px 40px rgba(37, 53, 71, 0.15);
}

@mixin shadow-tile {
  box-shadow: 0 0 15px map-get($viac-colors, viac-neutral-15);
}

@mixin effect-hover {
  box-shadow: 0 0 8px rgba(37, 53, 71, 0.5);
}

@mixin portfolio-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@mixin gradient-wealth-portfolio-card {
  background: linear-gradient(104.56deg, rgba(202, 225, 158, 0.2) 0.73%, rgba(160, 219, 168, 0.2) 48.87%);
}

@mixin gradient-button-primary-hover {
  background: linear-gradient(276.52deg, #253547 0%, #00595a 100%);
}

@mixin button-focused-shadow {
  box-shadow: 0 0 5px #5e9ed6;
}

@mixin shadow-fab {
  box-shadow: 0 0 40px rgba(37, 53, 71, 0.6);
}

@mixin shadow-fab-modal {
  box-shadow: 0 0 40px rgba(37, 53, 71, 0.25);
}

@mixin child-portfolio-color-green {
  @include gradient-portfolio-tile-default;
}

@mixin child-portfolio-color-light-blue {
  background: linear-gradient(93.5deg, rgba(156, 245, 229, 0.6) 3.44%, rgba(135, 233, 255, 0.6) 78.11%);
}

@mixin child-portfolio-color-blue {
  background: linear-gradient(93.5deg, rgba(162, 199, 255, 0.6) 3.44%, rgba(131, 210, 255, 0.6) 78.11%);
}

@mixin child-portfolio-color-pink {
  background: linear-gradient(93.5deg, rgba(207, 212, 251, 0.6) 3.44%, rgba(251, 205, 235, 0.6) 78.11%);
}

@mixin child-portfolio-color-orange {
  background: linear-gradient(93.5deg, rgba(255, 217, 189, 0.6) 3.44%, rgba(255, 172, 172, 0.6) 72.14%);
}

@mixin child-portfolio-color-yellow {
  background: linear-gradient(93.5deg, rgba(255, 243, 137, 0.6) 3.44%, rgba(255, 215, 178, 0.6) 78.11%);
}
