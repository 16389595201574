@use 'breakpoints' as *;
@use 'colors' as *;

.text-align {
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}

.product-info-layout {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.product-info-layout-right {
  justify-content: center;
  display: flex;
  align-items: flex-start;

  @include media-breakpoint-up(lg) {
    flex-basis: 50%;
  }

  @include media-breakpoint-down(md) {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.product-info-layout-left {
  @include media-breakpoint-up(lg) {
    flex-basis: 50%;
  }
}

.media {
  max-width: 12.5rem;

  @include media-breakpoint-up(md) {
    max-width: 21.5rem;
  }
}
