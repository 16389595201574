/* stylelint-disable selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */

@use 'breakpoints' as *;
@use 'colors' as *;
@use 'typography' as *;

.viac-material {
  .mat-mdc-form-field {
    @include text-default;
    @include weight-medium;

    width: 100%;

    .mat-mdc-form-field-focus-overlay {
      display: none; // disable focus ripples
    }

    .mdc-text-field--filled {
      padding: 0 16px 0 8px;
      border-radius: 0;

      &:hover {
        background-color: map-get($viac-colors, viac-neutral-5);

        .mdc-line-ripple::before {
          border-bottom-color: map-get($viac-colors, viac-neutral-100);
        }
      }

      &:not(.mdc-text-field--disabled) {
        background: transparent;

        .mdc-floating-label {
          color: map-get($viac-colors, viac-neutral-100);
        }

        .mdc-floating-label &:not(.mdc-floating-label--float-above) {
          color: map-get($viac-colors, viac-neutral-100);
        }

        .mdc-floating-label.mdc-floating-label--float-above {
          color: map-get($viac-colors, viac-neutral-75);
        }

        &.mdc-text-field--invalid .mdc-floating-label.mdc-floating-label--float-above {
          color: map-get($viac-colors, viac-error-100);
        }

        .mdc-text-field__input {
          color: map-get($viac-colors, viac-neutral-100);
        }

        .mdc-line-ripple::before {
          border-bottom-color: map-get($viac-colors, viac-neutral-100);
        }
      }

      &.mdc-text-field--focused {
        background-color: map-get($viac-colors, viac-neutral-5);
      }

      &.mdc-text-field--invalid {
        background: map-get($viac-colors, viac-error-5);

        .mdc-line-ripple::before {
          border-bottom-color: map-get($viac-colors, viac-error-100);
        }

        &:hover {
          background: map-get($viac-colors, viac-error-10);
        }
      }

      &.mdc-text-field--disabled {
        background: map-get($viac-colors, viac-neutral-0);

        .mdc-floating-label,
        .mdc-text-field__input {
          color: map-get($viac-colors, viac-neutral-60);
          -webkit-text-fill-color: map-get($viac-colors, viac-neutral-60);
          opacity: 1;
        }

        .mdc-line-ripple::before {
          border-bottom-color: map-get($viac-colors, viac-neutral-60);
        }
      }
    }

    .mat-mdc-form-field-infix {
      @include text-default;
      @include weight-medium;

      min-height: 48px;
      width: unset;

      @include media-breakpoint-up(md) {
        min-height: 56px;
      }
    }

    .mdc-text-field--filled .mat-mdc-form-field-infix {
      padding-top: 20px;
      padding-bottom: 7px;

      @include media-breakpoint-up(md) {
        padding-top: 22.5px;
        padding-bottom: 10.5px;
      }
    }

    .mdc-text-field--filled .mat-mdc-floating-label.mdc-floating-label {
      @include text-default;
      @include weight-medium;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 24px;

      @include media-breakpoint-up(md) {
        top: 26.5px;
      }
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-122%) scale(0.75);

      @include media-breakpoint-up(md) {
        transform: translateY(-103%) scale(0.75);
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      @include body-xs;
      @include weight-medium;

      padding-top: 4px;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      top: 4px;
      padding: 0 8px;
    }

    .mat-mdc-form-field-bottom-align::before {
      height: unset;
    }

    .mat-mdc-form-field-hint {
      color: map-get($viac-colors, viac-neutral-75);
    }

    &:not(.mat-focused) .counter {
      display: none;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 56px;
      height: 56px;

      .mat-mdc-button-persistent-ripple {
        border-radius: 0;
      }
    }
  }
}

/* stylelint-enable selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */
