/* stylelint-disable no-descending-specificity */
@use 'assets' as *;
@use 'breakpoints' as *;
@use 'colors' as *;
@use 'variables' as *;

@import 'swiper/css/bundle';

:root {
  --swiper-theme-color: #{map-get($viac-colors, viac-primary-100)};
}

viac-p3a-portfolios,
viac-pvb-portfolios,
viac-inv-portfolios {
  .swiper-slide {
    width: $portfolio-tile-small-width;

    @include media-breakpoint-up(md) {
      width: $portfolio-tile-large-width;
    }
  }
}

.portfolios-swiper-container {
  position: relative;
  margin-left: -$product-card-margin-sides-small;
  margin-right: -$product-card-margin-sides-small;

  viac-swiper-container {
    overflow: hidden visible;
  }

  .gradient {
    @include gradient-portfolios-swiper-left;

    position: absolute;
    top: -$portfolio-tile-shadow-size;
    width: $product-card-margin-sides-small;
    height: calc($portfolio-tile-small-height + 2 * $portfolio-tile-shadow-size + 1rem);
    z-index: 2;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      height: calc($portfolio-tile-large-height + 2 * $portfolio-tile-shadow-size + 1rem);
    }

    &.right {
      @include gradient-portfolios-swiper-right;

      right: 0;
    }
  }
}

viac-carousel {
  .swiper-slide {
    width: calc(100vw - 5.875rem);

    @include media-breakpoint-up(md) {
      width: 35.5rem;
    }

    @include media-breakpoint-up(lg) {
      width: 45.5rem;
    }
  }
}

.portfolio-name-swiper {
  .swiper-slide {
    width: fit-content;
  }
}

.insurance-type-swiper {
  .swiper-slide {
    width: fit-content;
  }
}

/* stylelint-enable no-descending-specificity */
