@use 'breakpoints' as *;

@include media-breakpoint-up(md) {
  .profile-container {
    padding-left: 1.5rem;
    margin-right: 3rem;

    @include media-breakpoint-up(lg) {
      margin-right: 9rem;
    }
  }
}

.profile-headline {
  margin-bottom: 1.5rem;
}

.profile-button {
  margin: 2.5rem auto 0;

  @include media-breakpoint-up(md) {
    margin: 2.5rem 0 0;
  }
}
