$product-card-margin-sides-small: 1.5rem;
$product-card-margin-sides-large: 3.5rem;
$product-card-overview-sides-small: 1.5rem;
$product-card-overview-sides-large: 1.5rem;
$portfolio-tile-small-width: 11.75rem;
$portfolio-tile-large-width: 13rem;
$portfolio-tile-small-height: 7rem;
$portfolio-tile-large-height: 7.375rem;
$portfolio-tile-shadow-size: 8px;
$safe-area-inset-top: var(--viac-safe-area-inset-top);
$navigation-top-height: calc(3.625rem + $safe-area-inset-top);
$navigation-bottom-height: calc(4rem + max(env(safe-area-inset-bottom), 1rem));
$navigation-header-height: 5.5rem;
$portfolio-name-swiper-height: 3.5rem;
$padding-small-layout: 1.5rem;
$product-detail-page-padding-sides: 1.5rem;
$tab-layout-content-padding-sides: 1.5rem;
$tab-layout-content-padding-sides-large: 3rem;
$main-overlap: 88px;
$grid-template-columns-xl: 0 132px 1fr 96px 0;
$grid-template-columns-xxl: 0 240px 1fr 96px 0;
$grid-template-columns-xxxl: minmax(0, 1fr) 240px minmax(982px, 1588px) 96px minmax(0, 1fr);
$p3a-layout-content-margin-top: 3rem;
$modal-header-height: calc(3.5rem + $safe-area-inset-top);

// Z-index master list
$zindex-table-header: 19;
$zindex-phone-number-input: 20;
$zindex-navigation: 90;
$zindex-logo: 89;
$zindex-navigation-background: 80;
$zindex-modal: 100;
$zindex-portfolios-backdrop: 89;
$zindex-fab-background: 80;
$zindex-fab: 85;
$zindex-notification: 1001;
$zindex-loading-blocker: 1040;
$zindex-loading-spinner: 1050;
$zindex-drop-shadow: 1;

// Z-index modal list
$zindex-modal-dialog: 0;
$zindex-modal-sticky-headline: 20;
$zindex-modal-sticky-headline-closer: 21;
$zindex-modal-over-modal: 100;
