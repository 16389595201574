@use 'breakpoints' as *;
@use 'colors' as *;

.viac-material {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.alert-backdrop {
    background-color: rgba(map-get($viac-colors, viac-neutral-100), 0.7);
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.tooltip-backdrop {
    background-color: rgba(map-get($viac-colors, viac-neutral-0), 0.6);

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }

  // applies to all material datepicker popups
  .cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
    background-color: rgba(map-get($viac-colors, viac-neutral-100), 0.7);
  }
}
