@use 'breakpoints' as *;
@use 'colors' as *;
@use 'variables' as *;

.product-overview-layout {
  width: 100%;
  display: grid;
  grid-template:
    'left' auto
    'right' auto
    / minmax(0, 1fr);
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    grid-template: 'left right' auto / minmax(0, 1fr);
  }

  @include media-breakpoint-between(lg, xxl) {
    grid-template-columns: minmax(0, 60%) minmax(0, 40%);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

%product-overview-column {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    gap: 1.5rem;
  }

  > * {
    flex-basis: 100%;
    flex-grow: 1;
  }
}

.product-overview-layout-left {
  @extend %product-overview-column;

  grid-area: left;

  @include media-breakpoint-up(lg) {
    padding-right: 1.5rem;
    border-right: 1px solid map-get($viac-colors, viac-neutral-15);
  }
}

.product-overview-layout-right {
  @extend %product-overview-column;

  grid-area: right;

  @include media-breakpoint-up(lg) {
    padding-left: 1.5rem;
  }
}

.product-overview-side-margins {
  @include media-breakpoint-down(md) {
    margin-left: -$product-card-overview-sides-small;
    margin-right: -$product-card-overview-sides-small;
  }

  @include media-breakpoint-between(md, lg) {
    margin-left: -$product-card-overview-sides-large;
    margin-right: -$product-card-overview-sides-large;
  }
}
