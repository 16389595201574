a,
a:not([href]),
a:not([tabindex]),
a:not([href]):not([tabindex]) {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.show-cursor {
  cursor: pointer;
}

.m-link {
  font-weight: 500;
  letter-spacing: 0.02em;
}

.s-link {
  font-weight: 400;
}

a.download-button {
  // Needed so the <a> does not fill the whole page width but is only as wide as the button it contains.
  display: block;
  width: fit-content;

  // We don't want an underline on the text inside the button
  text-decoration: none;
}
