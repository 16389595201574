@use 'breakpoints' as *;
@use 'typography' as *;

.viac-material {
  .mat-mdc-form-field.code-form-field {
    @include h4-style;

    ::placeholder {
      letter-spacing: 0.002em;
      text-indent: 0;
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
      font-size: 1.125rem;

      @include media-breakpoint-up(md) {
        font-size: 1.375rem;
      }

      letter-spacing: 0.75rem;
      text-indent: 0.75rem;
      text-align: center;
    }
  }
}
