/* stylelint-disable selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */
@use 'breakpoints' as *;
@use 'colors' as *;
@use 'typography' as typography;
@use 'variables' as *;

.viac-material {
  .mat-mdc-header-cell {
    /* stylelint-disable-next-line declaration-no-important */
    z-index: $zindex-table-header !important;
  }

  .mat-mdc-header-cell,
  mat-mdc-cell {
    @include typography.body-m;

    color: map-get($viac-colors, viac-neutral-100);
  }

  .mat-mdc-paginator .mat-mdc-paginator-container {
    @include typography.body-m;

    .mdc-notched-outline {
      border-bottom: 1px solid;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border: 0;
      }
    }

    .mat-mdc-select-value {
      font-size: 1rem;
    }

    .mat-mdc-paginator-page-size-select {
      width: 5.75rem;
    }
  }

  .basic-table.mat-mdc-table {
    width: 100%;

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      padding: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.05rem;
      vertical-align: middle;

      @include media-breakpoint-down(md) {
        padding: 0.5rem 1.5rem;
      }
    }

    .mat-mdc-header-row {
      height: 3rem;
      background-color: map-get($viac-colors, viac-neutral-5);
      border-bottom: 1px solid map-get($viac-colors, viac-neutral-15);

      @include media-breakpoint-down(md) {
        position: sticky;
        top: $navigation-top-height;
      }

      .mat-mdc-header-cell {
        font-size: 0.75rem;
      }
    }
  }

  .mat-mdc-row {
    height: 3rem;
    border-bottom: 1px solid map-get($viac-colors, viac-neutral-15);
  }

  table:not(.no-hover-table) {
    .mat-mdc-row {
      cursor: pointer;

      &:hover:not(.no-hover-row) {
        background-color: map-get($viac-colors, viac-neutral-5) !important; // stylelint-disable-line declaration-no-important

        .highlighted-cell:hover {
          background-color: map-get($viac-colors, viac-neutral-15) !important; // stylelint-disable-line declaration-no-important
          cursor: pointer;
        }
      }

      &.no-hover-row {
        background-color: unset;
        cursor: unset;
      }
    }
  }

  .paginator {
    margin-top: 1rem;
  }
}
/* stylelint-enable selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */
