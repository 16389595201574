@use 'src/theme/colors' as *;

/* stylelint-disable declaration-no-important, selector-class-pattern */

.viac-material {
  .mat-mdc-menu-panel {
    border-radius: 1rem !important;

    .mat-mdc-menu-item-text {
      font-size: 0.875rem !important;
    }
  }
}

/* stylelint-enabled declaration-no-important, selector-class-pattern */
