@use 'theme/theme' as *;

:root {
  --viac-safe-area-inset-top: var(--viac-android-safe-area-inset-top, env(safe-area-inset-top));
}

body {
  overflow-y: scroll;

  &.native-context {
    user-select: none;
  }
}
