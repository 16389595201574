.tooltip-layout {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
}

.tooltip-font-big {
  font-size: 1rem;
  font-weight: 500;
}

.tooltip-font-small {
  font-size: 0.75rem;
  font-weight: 400;
}
